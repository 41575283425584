import { render, staticRenderFns } from "./BiAnBaoAssetDetails.vue?vue&type=template&id=136abdb7&scoped=true&"
import script from "./BiAnBaoAssetDetails.vue?vue&type=script&lang=js&"
export * from "./BiAnBaoAssetDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "136abdb7",
  null
  
)

export default component.exports